import React, { useMemo } from 'react';
import { Table, EmptyList } from '@components/collection';
import { InUse, Button } from '@components';

export default function List({
  response, onEditButtonClick, routes, onDelete, selectedResourceId,
}) {
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Updated at (UTC)',
        accessor: 'updatedAt',
      },

      InUse.InUseColumn,

      {
        Header: 'Actions',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row: {
            original: {
              id, inUse, name, permissions,
            },
          },
        }) => {
          const isInUse = InUse.isInUse(inUse);

          return (
            <>
              {permissions.edit && <Button.Edit className="me-2" onClick={() => onEditButtonClick(id)} />}
              {!isInUse && permissions.destroy && (
                <Button.DeleteConfirm
                  id={id}
                  routes={routes}
                  onDelete={onDelete}
                  recordName={`Preset "${name}"`}
                />
              )}
            </>
          );
        },
      },
    ],
    [],
  );

  if (!response || response.items.length < 1) {
    return <EmptyList title="No configurations yet." />;
  }

  return (
    <Table columns={columns} selectedRowId={selectedResourceId} data={response.items} />
  );
}

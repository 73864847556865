/* eslint-disable jsx-a11y/no-static-element-interactions */
// source: https://github.com/iamjuliosampaio/splitpane-react

import React, {
  createRef,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import styled from 'styled-components';
import { useLocalStorage } from '@tripledotstudios/react-core';
import { HEADER_HEIGHT_REM } from './PageHeader';

const StyledDivider = styled.div`
  background: #000;
  opacity: 0.4;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
  height: 11px;
  margin: -5px 0;
  cursor: row-resize;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;

  margin: 5px 0;

  &:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  }
`;

const minTopHeightRem = 4;
const minBottomHeightRem = 4;

export const StyledPaneWrapper = styled.div`
  height: calc(100vh - ${HEADER_HEIGHT_REM}rem);
  display: flex;
  flex-direction: column;
`;

export const StyledPane = styled.div`
  flex: 1;
  overflow: auto;
`;

const StyledPaneBottom = styled(StyledPane)`
  padding-top: 3px; // to fix inputs in the top
  overflow-x: hidden;
`;

const convertRemToPixels = (rem) => (
  rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
);
const headerHeight = convertRemToPixels(HEADER_HEIGHT_REM);
const minTopHeight = convertRemToPixels(minTopHeightRem);
const minBottomHeight = convertRemToPixels(minBottomHeightRem);

const SplitPaneContext = createContext();

const SplitPane = ({ children, paneName, ...props }) => {
  const [clientHeight, setClientHeight] = useState(null);
  const yDividerPos = useRef(null);
  /* eslint-disable no-mixed-operators */
  const [paneHeight, setPaneHeight] = useLocalStorage(`pane_size_${paneName || 'all'}`, 50);
  const wrapperHeight = window.innerHeight - headerHeight - minBottomHeight;

  const onMouseHoldDown = (e) => {
    yDividerPos.current = e.clientY;
  };

  const onMouseHoldUp = () => {
    yDividerPos.current = null;
  };

  const allowStoreHeight = (newHeight) => {
    let finalHeight = newHeight;
    let allowedToChange = true;

    if (finalHeight > wrapperHeight) {
      finalHeight = wrapperHeight;
      allowedToChange = false;
    }
    if (finalHeight < minTopHeight) {
      finalHeight = minTopHeight;
      allowedToChange = false;
    }

    setPaneHeight(Math.round(finalHeight / wrapperHeight * 100));
    return allowedToChange;
  };

  const onMouseHoldMove = (e) => {
    if (!yDividerPos.current) {
      return;
    }

    const newHeight = clientHeight + e.clientY - yDividerPos.current;

    if (allowStoreHeight(newHeight)) {
      setClientHeight(newHeight);
      yDividerPos.current = e.clientY;
    }

    if (e.stopPropagation) e.stopPropagation();
    if (e.preventDefault) e.preventDefault();
    e.cancelBubble = true;
    e.returnValue = false;
  };

  const initPaneHeight = () => (Math.round(paneHeight / 100 * wrapperHeight)); /* eslint-disable no-mixed-operators */

  useEffect(() => {
    document.addEventListener('mouseup', onMouseHoldUp);
    document.addEventListener('mousemove', onMouseHoldMove);

    return () => {
      document.removeEventListener('mouseup', onMouseHoldUp);
      document.removeEventListener('mousemove', onMouseHoldMove);
    };
  });

  const value = useMemo(() => (
    {
      clientHeight,
      setClientHeight,
      onMouseHoldDown,
      initPaneHeight: initPaneHeight(),
    }
  ), [clientHeight, setClientHeight, onMouseHoldDown, initPaneHeight()]);

  return (
    <StyledPaneWrapper {...props}>
      <SplitPaneContext.Provider value={value}>
        {children}
      </SplitPaneContext.Provider>
    </StyledPaneWrapper>
  );
};

SplitPane.Divider = (props) => {
  const { onMouseHoldDown } = useContext(SplitPaneContext);

  return <StyledDivider {...props} onMouseDown={onMouseHoldDown} />;
};

SplitPane.Top = ({ children, ...props }) => {
  const topRef = createRef();
  const { clientHeight, setClientHeight, initPaneHeight } = useContext(SplitPaneContext);

  useEffect(() => {
    if (!clientHeight) {
      if (+initPaneHeight > 0) {
        setClientHeight(initPaneHeight);
      } else {
        setClientHeight(topRef.current.clientHeight);
      }
      return;
    }

    topRef.current.style.minHeight = `${clientHeight}px`;
    topRef.current.style.maxHeight = `${clientHeight}px`;
  }, [clientHeight]);

  return (
    <StyledPane {...props} ref={topRef}>
      {children}
    </StyledPane>
  );
};

SplitPane.Bottom = ({ children, ...props }) => (
  <StyledPaneBottom {...props}>
    {children}
  </StyledPaneBottom>
);

export default SplitPane;

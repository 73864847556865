import React, { useEffect, useState } from 'react';
import { Table, Badge } from 'react-bootstrap';
import { map } from 'lodash';
import { useFieldArray } from 'react-hook-form';
import { PageSection, FormGroup, useFormGroup } from '@tripledotstudios/react-core';

import { useAppData } from '@hooks';
import { EmptyList } from '@components/collection';
import DynamicParameters from './Inputs';

export default function ParametersForm({
  DiffWrapper, sourceEntity, newSourceEntity, allowedAssetBundlesTypes, entityName = 'entity',
}) {
  const formGroupName = 'dynamicParametersAttributes';
  const { generateName } = useFormGroup();
  const [templateAttributes, setTemplateAttributes] = useState();
  const templateTypes = useAppData().localizedEnums.DynamicParameterTemplateTypes;
  const rootGroupName = generateName(formGroupName);

  const { fields: dynamicParameterAttributes, replace } = useFieldArray({ name: rootGroupName, keyName: 'uiKey' });

  useEffect(() => {
    if (!sourceEntity) return;

    setTemplateAttributes(sourceEntity.dynamicParameterTemplatesAttributes);
  }, []);

  useEffect(() => {
    if (!newSourceEntity) return;

    const newData = [];

    dynamicParameterAttributes.forEach((data) => data.id && newData.push({ ...data, _destroy: true }));
    newSourceEntity.dynamicParameterTemplatesAttributes
      .forEach(({ id, defaultValue }) => newData.push({ templateId: id, value: defaultValue }));

    setTemplateAttributes(newSourceEntity.dynamicParameterTemplatesAttributes);
    replace(newData);
  }, [newSourceEntity]);

  const indexFor = (tId) => dynamicParameterAttributes
    .findIndex(({ templateId, _destroy }) => !_destroy && +templateId === +tId);

  const renderInput = (typeName, label, fullGroupName, listOptions) => DynamicParameters[typeName].input({
    name: 'value',
    formGroupName: fullGroupName,
    label,
    listOptions,
    allowedAssetBundlesTypes,
  });

  return (
    <PageSection title="Parameters">
      {templateAttributes?.length > 0 ? (
        <Table size="sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Key</th>
              <th>Value</th>
              <th>Type</th>
              <th>Default value</th>
            </tr>
          </thead>
          <tbody>
            {map(templateAttributes, ({
              id: templateId, name, key, typeName, defaultValueHuman, defaultValueDeleted, listOptions,
            }) => {
              const fullGroupName = `${rootGroupName}.${indexFor(templateId)}`;
              const localGroupName = `${formGroupName}.${indexFor(templateId)}`;

              return (
                <FormGroup key={templateId} name={localGroupName}>
                  <tr>
                    <td>{name}</td>
                    <td>{key}</td>
                    <td>
                      {DiffWrapper ? (
                        <DiffWrapper for="value">
                          {renderInput(typeName, name, fullGroupName, listOptions)}
                        </DiffWrapper>
                      ) : renderInput(typeName, name, fullGroupName, listOptions)}
                    </td>
                    <td>{templateTypes[typeName.toUpperCase()]}</td>
                    <td>
                      {defaultValueHuman}
                      {defaultValueDeleted && <Badge className="ms-1" bg="secondary">deleted</Badge>}
                    </td>
                  </tr>
                </FormGroup>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <EmptyList title={`No available parameters for the selected ${entityName}`} />
      )}
    </PageSection>
  );
}

import React, { useMemo } from 'react';
import { useAppData } from '@hooks';
import { Table, EmptyList, Status } from '@components/collection';
import { Button, InUse } from '@components';
import { hasApplicationPermissions } from '@services/permissions';
import getMap from '@services/getMap';

export default function List({
  response, onEditButtonClick, routes, onDelete, onDuplicateButtonClick, selectedResourceId, query,
}) {
  const canCreate = hasApplicationPermissions(['events', 'configuration', 'write']);
  const statuses = getMap(useAppData().enumOptions['Events::ConfigurationStatuses']);

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Priority',
        accessor: 'priority',
      },
      {
        Header: 'Status',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row: { original: { status } } }) => <Status value={statuses[status]} />,
      },
      InUse.AvailabilityStateColumn,
      {
        Header: 'Event type',
        accessor: 'typeName',
      },
      {
        Header: 'Start At',
        accessor: 'startAt',
      },
      {
        Header: 'End At',
        accessor: 'endAt',
      },
      {
        Header: 'Updated at (UTC)',
        accessor: 'updatedAt',
      },
      {
        Header: 'Actions',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row: {
            original: {
              id, name, priority, permissions,
            },
          },
        }) => (
          <>
            {permissions.edit && <Button.Edit className="me-2" onClick={() => onEditButtonClick(id)} />}
            {canCreate && (
              <Button.Duplicate
                className="me-2"
                onClick={() => onDuplicateButtonClick({ id, name, priority })}
              />
            )}
            {permissions.destroy && (
              <Button.DeleteConfirm
                id={id}
                routes={routes}
                onDelete={onDelete}
                recordName={`Events Configuration "${name}"`}
              />
            )}
          </>
        ),
      },
    ],
    [JSON.stringify(query)],
  );

  if (!response || response.items.length < 1) {
    return <EmptyList title="No configurations yet." />;
  }

  return (
    <Table columns={columns} selectedRowId={selectedResourceId} data={response.items} />
  );
}

import {
  useContext,
  createContext,
  useState,
  useEffect,
} from 'react';
import { mapValues, values } from 'lodash';

import { SystemRoutes as Routes } from '@pages/routes';

/** @typedef {{ [Key in keyof RawAppEnums]: { [Option in keyof RawAppEnums[Key]]: string } }} AppLocalizedEnums */
/**
 * @typedef {Object} AppData
 * @property {AppEnums} enums
 * @property {AppLocalizedEnums} localizedEnums
 * @property {AppI18n} i18n
 * @property {AppFeatureToggles} featureToggles
 * @property {AppEnumsOptions} enumOptions
 * @property {RawAppPermissions} permissions
 */

/** @type {import('react').Context<Record<string, any> & AppData>} */
const AppDataContext = createContext({});

const shortEnvironmentNameMap = {
  development: 'DEV',
  feature: 'FB',
  staging: 'STG',
  production: 'PRD',
};

const presentAppData = (rawData) => {
  const enumsToOptions = (enums) => mapValues(enums, (klassEnum) => (
    values(klassEnum).map(({ value, translation: label }) => ({ value, label }))
  ));

  return {
    enums: mapValues(rawData.enums, (klassEnum) => mapValues(klassEnum, 'value')),
    localizedEnums: mapValues(rawData.enums, (klassEnum) => mapValues(klassEnum, 'translation')),
    environment: rawData.environment,
    shortEnvironment: shortEnvironmentNameMap[rawData.environment],
    i18n: rawData.i18n,
    featureToggles: rawData.feature_toggles,
    enumOptions: enumsToOptions(rawData.enums),
    applications: rawData.applications,
    permissions: rawData.permissions,
  };
};

const ProvideAppData = ({ children }) => {
  const [data, setData] = useState();

  let loadData;
  const processResponse = (response) => setData({ ...presentAppData(response.data), reload: loadData });

  loadData = () => Routes.appDataRequest({}, { skipInboudProcessing: true }).then(processResponse);

  useEffect(() => { loadData(); }, []);

  return (
    <AppDataContext.Provider value={data}>
      {data && children(data)}
    </AppDataContext.Provider>
  );
};

const useAppData = () => useContext(AppDataContext);

export {
  useAppData,
  ProvideAppData,
};

import { useQuery } from '@tanstack/react-query';
import { EventsRoutes } from '@pages/routes';
import { toOptions } from '@services/utils';

const LIST_QUERY_NAME_KEY = 'events/configurations';
export const DEFAULT_LOADER_STALE_TIME = 1000;

function configurationsQuery({
  params = {},
  transform,
  staleTime = DEFAULT_LOADER_STALE_TIME,
  ...opts
}) {
  return {
    queryKey: [LIST_QUERY_NAME_KEY, params],
    queryFn: async ({ queryKey }) => {
      const { data } = await EventsRoutes.Configurations.indexRequest(queryKey[1]);

      if (transform) {
        return transform(data);
      }

      return data;
    },
    staleTime,
    ...opts,
  };
}

export function useEventsConfigurationsOptions(query = {}) {
  return useQuery(configurationsQuery({
    params: {
      ...query,
      includeInUse: false,
      withoutPagination: true,
    },
    transform: (data) => toOptions(data.items, {
      transform: (option, {
        availabilityStateLive, availabilityState, status, availableNow,
      }) => Object.assign(option, {
        availabilityStateLive,
        availabilityState,
        status,
        availableNow,
      }),
    }),
  }));
}

export function useEventsConfigurations(query = {}) {
  return useQuery(configurationsQuery({ params: { ...query, includeInUse: false, withoutPagination: true } }));
}

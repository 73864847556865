import React from 'react';
import { sumBy } from 'lodash';
import { Alert } from '@tripledotstudios/react-core';

import durationText from '@services/durationText';

const displayDuration = (duration) => (duration === 0 ? '-' : durationText(duration));

export default function RecurrenceSettings({ activities }) {
  const activitiesDuration = sumBy(activities, (a) => (a.duration || 0));
  const cooldownDuration = sumBy(activities, (a) => (a.cooldownBeforeNextActivity || 0));

  return (
    <Alert variant="success mt-3">
      <div className="flex-grow-1">
        {`Summarised duration: ${displayDuration(activitiesDuration)}. `}
        {`Summarised duration cooldown: ${displayDuration(cooldownDuration)}. `}
        {`Total occurrence length: ${displayDuration(activitiesDuration + cooldownDuration)}`}
      </div>
    </Alert>
  );
}

import { useFormContext } from 'react-hook-form';
import { useFormGroup, Alert } from '@tripledotstudios/react-core';
import { Button } from 'react-bootstrap';

import { useAppData } from '@hooks';
import { LabeledSwitch, DiffWrapper, DiffProvider } from '@components/resource';
import { ParametersForm } from '@components/dynamic_parameters';

import ConfigurationObjectives from './ConfigurationObjectives';
import RecurrenceSettings from './RecurrenceSettings';
import RecurrenceSummary from './RecurrenceSummary';

export default function ConfigurationVariant({
  currentAttributes,
  configurationOptions,
  configurationTypesOptions,
  defaultVariantAttributes,
  segments,
  type,
  newType,
  isBaseConfigView,
  openBaseView,
  recurring,
}) {
  const { watch } = useFormContext();
  const { generateName } = useFormGroup();

  const assetBundlesTypes = [useAppData().enums.AssetBundleTypes.HAWK_EVENT];
  const eventEnabled = watch(generateName('enabled'));

  if (currentAttributes?.isDefaultVariant && !isBaseConfigView) {
    return (
      <Alert variant="warning">
        <div className="ms-2 d-flex align-items-center">
          <div className="flex-grow-1">
            <div>
              Control configuration is based on the base config.
            </div>
            Any changes done in the base config will be reflected in the Control of experiment.
          </div>
          <Button variant="link" className="text-nowrap" onClick={openBaseView}>Go to Base Config</Button>
        </div>
      </Alert>
    );
  }

  const activities = currentAttributes?.configurationObjectivesAttributes || [];
  const resolvedType = newType || type;

  return (
    <DiffProvider baseAttributes={defaultVariantAttributes} currentAttributes={currentAttributes}>
      <DiffWrapper for="enabled">
        <LabeledSwitch
          name="enabled"
          label="Serve the configuration to players"
          sizes={{
            xxl: [3, 4], xl: [4, 4], lg: [4, 4], md: [6, 4], sm: [10, 2], xs: [10, 2],
          }}
        />
      </DiffWrapper>
      {eventEnabled && resolvedType && (
        <>
          {recurring && <RecurrenceSettings activities={activities} />}
          <ParametersForm
            newSourceEntity={newType}
            sourceEntity={type}
            DiffWrapper={DiffWrapper}
            allowedAssetBundlesTypes={assetBundlesTypes}
            entityName="Events Type"
          />
          <ConfigurationObjectives
            maxActivities={resolvedType.maxActivities}
            objectiveConfigurationsOptions={configurationOptions}
            objectiveConfigurationTypesOptions={configurationTypesOptions}
            segments={segments}
            recurring={recurring}
          />
          {recurring && <RecurrenceSummary activities={activities} />}
        </>
      )}
    </DiffProvider>
  );
}

import React from 'react';

import { Form, FormContainer } from '@components';
import { LabeledInput } from '@components/resource';

export default function PresetsForm(props) {
  return (
    <Form.Resource {...props}>
      <FormContainer>
        <LabeledInput label="Name" name="name" />
      </FormContainer>
    </Form.Resource>
  );
}

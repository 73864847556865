import CreativeLayout from './CreativeLayout';
import ChainedLayout from './ChainedLayout';

export default function ChainedLayoutSupport({
  availabilityCap,
  creativeLayoutsDataAttributes,
  template,
  appLinkConfigurations,
  localisationKeys,
  chainedEnabled,
  replaceCreativeLayoutData,
}) {
  return chainedEnabled ? (
    <ChainedLayout
      availabilityCap={availabilityCap}
      creativeLayoutsDataAttributes={creativeLayoutsDataAttributes}
      layoutDataAttributeName="creativeLayoutsDataAttributes"
      template={template}
      appLinkConfigurations={appLinkConfigurations}
      localisationKeys={localisationKeys}
      replaceCreativeLayoutData={replaceCreativeLayoutData}
    />
  ) : (
    <CreativeLayout
      availabilityCap={availabilityCap}
      creativeLayoutsDataAttributes={creativeLayoutsDataAttributes}
      layoutDataAttributeName="creativeLayoutsDataAttributes"
      template={template}
      appLinkConfigurations={appLinkConfigurations}
      localisationKeys={localisationKeys}

    />
  );
}

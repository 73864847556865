import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRouter } from '@tripledotstudios/react-core';

import { useAppData, useQuery } from '@hooks';
import { findById, toOptions } from '@services/utils';
import { ExperimentTabs } from '@components/ab_testing';
import { ABTestingRoutes, EventsRoutes } from '@pages/routes';

import ConfigurationVariant from './ConfigurationVariant';

function configurationOptionsTransform(option, { availabilityState, availabilityStateLive }) {
  return Object.assign(option, {
    availabilityState,
    availabilityStateLive,
    label: `#${option.value} - ${option.label}`,
  });
}

export default function ConfigurationVariants({
  variantsEvents, segments, types, recurring,
}) {
  const { query } = useRouter();
  const { getValues } = useFormContext();
  const [newType, setNewType] = useState(null);
  const { id: eventId, typeId } = getValues();
  const type = findById(types, typeId);
  const { enums, enumOptions } = useAppData();
  const activityTypes = enums['Events::ActivityTypes'];
  const activityTypesOptions = enumOptions['Events::ActivityTypes'];

  const reloadEntityRequest = () => EventsRoutes.Configurations.editRequest({ id: eventId, ...query });

  const { response: experiments } = useQuery(
    ABTestingRoutes.Experiments.indexRequest,
    { ...query, withoutPagination: true },
  );
  const { response: objectiveConfigurations, isLoading: isObjectivesLoading } = useQuery(
    (params) => EventsRoutes.ObjectiveConfigurations.indexRequest(params, { showAlert: false }),
    { ...query, withoutPagination: true },
  );
  const { response: collectionSets, isLoading: isCollectionSetsLoading } = useQuery(
    (params) => EventsRoutes.CollectionSets.indexRequest(params, { showAlert: false }),
    { ...query, withoutPagination: true },
  );
  const { response: races, isLoading: isRacesLoading } = useQuery(
    (params) => EventsRoutes.Races.indexRequest(params, { showAlert: false }),
    { ...query, withoutPagination: true },
  );
  const { response: clientLeaderboards, isLoading: isLeaderboardsLoading } = useQuery(
    (params) => EventsRoutes.ClientLeaderboards.indexRequest(params, { showAlert: false }),
    { ...query, withoutPagination: true },
  );

  const configurationOptions = useMemo(() => ({
    [activityTypes.OBJECTIVE]: toOptions(objectiveConfigurations?.items || [], {
      transform: configurationOptionsTransform,
    }),
    [activityTypes.COLLECTION_SET]: toOptions(collectionSets?.items || [], {
      transform: configurationOptionsTransform,
    }),
    [activityTypes.RACE]: toOptions(races?.items || [], {
      transform: configurationOptionsTransform,
    }),
    [activityTypes.CLIENT_LEADERBOARD]: toOptions(clientLeaderboards?.items || [], {
      transform: configurationOptionsTransform,
    }),
  }), [objectiveConfigurations?.items?.length, collectionSets?.items?.length, races?.items?.length]);

  const handleTypeChange = (newTypeId) => setNewType(findById(types, newTypeId));

  useEffect(() => {
    /* eslint-disable no-param-reassign */
    variantsEvents.handleTypeChange = handleTypeChange;
  }, []);

  return experiments?.items && !isObjectivesLoading && !isCollectionSetsLoading
    && !isRacesLoading && !isLeaderboardsLoading ? (
      <ExperimentTabs
        entityId={eventId}
        entityType="Events::Configuration"
        experiments={experiments.items}
        reloadEntityRequest={reloadEntityRequest}
        renderView={({
          variantAttributes,
          defaultVariant,
          openBaseView,
          isBaseConfigView,
        }) => (
          <ConfigurationVariant
            configurationOptions={configurationOptions}
            configurationTypesOptions={activityTypesOptions}
            segments={segments}
            type={type}
            newType={newType}
            defaultVariantAttributes={!variantAttributes.isDefaultVariant && defaultVariant}
            currentAttributes={variantAttributes}
            isBaseConfigView={isBaseConfigView}
            openBaseView={openBaseView}
            recurring={recurring}
          />
        )}
      />
    ) : null;
}

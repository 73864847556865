import React from 'react';
import styled from 'styled-components';
import { get, isObject } from 'lodash';
import classnames from 'classnames';
import { useFormGroup } from '@tripledotstudios/react-core';
import { useDiff } from '@components/resource';

const DiffWrapperStyled = styled.div`
  .has-diff {
    background-color: #fff8e4 !important;

    div[class*="-control"], input.form-control {
      background-color: #fff8e4 !important;
    }

    span.input-group-text {
      background-color: #fff1cd !important;
    }
  }
`;

export default function DiffWrapper({
  for: key, children, className, disabled = false,
}) {
  const { generateName } = useFormGroup();
  const { diff, fieldNameBase } = useDiff();
  const diffPath = generateName(key).replace(fieldNameBase, '');

  return (
    <DiffWrapperStyled>
      <div className={classnames(className, { 'has-diff': !disabled && isObject(diff) && get(diff, diffPath, true) })}>
        {children}
      </div>
    </DiffWrapperStyled>
  );
}

import React from 'react';

import { LabeledSelect } from '@components/resource';

export default function PresetSelect({ presets }) {
  const presetsOptions = [
    { label: 'None', value: '' },
    ...presets.items.map(({ id, name }) => ({ label: name, value: id })),
  ];

  return (
    <LabeledSelect
      label="Preset"
      name="presetId"
      options={presetsOptions}
      sizes={[1, 5]}
    />
  );
}
